<template>
  <div
    class="inline-flex items-center rounded-[4px] font-normal"
    :class="[classes]"
  >
    <slot>{{ label }}</slot>
  </div>
</template>

<script setup lang="ts">
const colorClasses = {
  default: 'bg-black text-white',
  highlight: 'bg-[#006BFF] text-white',
  info: 'bg-[#B8BCC3] text-white',
  warning: 'bg-[#CB3226] text-white',
}

const sizeClasses = {
  small: 'px-2 py-0.5 text-xs md:text-[15px] font-medium',
  large: 'px-4 py-1 text-lg leading-6 uppercase',
}

const props = defineProps({
  type: {
    type: String as PropType<'default' | 'highlight' | 'info' | 'warning'>,
    required: true,
  },
  size: {
    type: String as PropType<'small' | 'large'>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
})

const classes = computed(() => [
  colorClasses[props.type],
  sizeClasses[props.size],
])
</script>
